import { combineReducers } from "redux";
import { linkReducer } from "./listReducer";
import { tagReducer } from "./tagReducer";
import { colorReducer } from "./colorReducer";

const reducers = combineReducers({
    allLinks: linkReducer,
    tagList: tagReducer,
    colorList: colorReducer,
});

export default reducers;
