import { ActionTypes } from "../contants/action-types";
export const ListLinks = (links) => {
    return {
        type: ActionTypes.LINK_LISTS,
        payload: links,
    };
};

export const SingleLink = (link) => {
    return {
        type: ActionTypes.SINGLE_LINK,
        payload: link,
    };
};
