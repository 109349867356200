import React from "react";
import Select from "react-select";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        color: state.data.color,
    }),
    control: (provided) => ({
        ...provided,
        width: 200,
        height: 50,
        minHeight: 50,
    }),
};
const formatOptionLabel = ({ color, label }) => (
    <div style={{ display: "flex", alignItems: "center", height: "50px" }}>
        <span
            style={{
                height: "15px",
                width: "15px",
                borderRadius: "50%",
                backgroundColor: color,
                marginRight: "10px",
            }}
        />
        {label}
    </div>
);

const ColorSelect = ({ listColors, onColorChange }) => {
    const colorOptions = listColors?.map((color) => ({
        value: color,
        label: color,
        color: color,
    }));
    return (
        <Select
            options={colorOptions}
            styles={customStyles}
            formatOptionLabel={formatOptionLabel}
            placeholder="Select a color"
            isSearchable={false}
            onChange={onColorChange}
        />
    );
};

export default ColorSelect;
