import React from 'react';
import "../Style/coomingsoon.css";
import heroImage from "../Images/coming-soon-bg.png";
import lineBG from "../Images/line-bg.svg";
import Header from './Header';

const CommingSoon = () => {
    return (
        <div>
        <Header />
  
        <section className={`coming-soon-section page-bg text-center pb-4`}>
          <div className={`container section--pading`}>
            <img src={heroImage} alt={`coming soon background`} width="80%" />
          </div>
          <img src={lineBG} alt="" width="100%" />
          <div className={`container`}>
            <h1 className={`coming-soon-text`}>Coming Soon!</h1>
            <p className={`text-dark coming-subtext`}>
              We are currently working hard building this page
            </p>
          </div>
        </section>
      </div>
    )
}

export default CommingSoon