import { ActionTypes } from "../contants/action-types";

const initialState = {
    colors: [],
};

export const colorReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.COLORS_LIST:
            return { ...state, colors: payload };
        default:
            return state;
    }
};
