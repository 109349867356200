import React, { useEffect } from "react";
import Header from "./Header";
import SideBar from "./SideBar";
import Detail from "./Detail";
import queryString from "query-string";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { SingleLink } from "../redux/actions/LinksActions";

const DetailComponent = () => {
    const apiurl = "https://punchlinehub.com/";
    const dispatch = useDispatch();

    const value = queryString.parse(window.location.search);

    const token = value.pageId;
    const pageURL = token;

    const fetchLinks = async () => {
        const response = await axios
            .post(`${apiurl}api/getSingleLink`, {
                id: pageURL,
            })
            .catch((error) => {
                console.log(error);
            });
        dispatch(SingleLink(response.data));
    };

    useEffect(() => {
        fetchLinks();
    }, []);

    return (
        <>
            <Header />
            <div className="page-wrapper flex-column-reverse flex-lg-row sticky-sidebar-custom">
                <SideBar />
                <Detail />
            </div>
        </>
    );
};

export default DetailComponent;
