import { ActionTypes } from "../contants/action-types";

const initialState = {
    links: [],
};

export const tagReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.TAG_LIST:
            return { ...state, tags: payload };
        default:
            return state;
    }
};
