import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { ListTags } from "../redux/actions/TagActions";
import { ListColors } from "../redux/actions/ColorActions";
import ColorSelect from "./ColorSelect";
import { ListLinks } from "../redux/actions/LinksActions";
import BannerClip1 from "../Images/banner-clip-1.png";
import BannerClip2 from "../Images/banner-clip-2.png";
import BannerClip3 from "../Images/banner-clip-3.png";
import BannerClip4 from "../Images/banner-clip-4.png";
import BannerBackground from "../Images/banner-background.png";

const BannerSection = () => {
    const apiurl = "https://punchlinehub.com/";
    const dispatch = useDispatch();
    const [counter, setCounter] = useState(0);
    const listTags = useSelector((state) => state.tagList.tags);
    const listColors = useSelector((state) => state.colorList.colors);
    const linksList = useSelector((state) => state.allLinks.links);

    const fetchTags = async () => {
        const response = await axios
            .get(`${apiurl}api/getHomePageTags`)
            .catch((error) => {
                console.log(error);
            });
        dispatch(ListTags(response?.data?.data));
    };
    const fetchColors = async () => {
        const response = await axios
            .get(`${apiurl}api/getHomePageColors`)
            .catch((error) => {
                console.log(error);
            });
        dispatch(ListColors(response?.data?.data));
    };

    const handleChange = async (event) => {
        const selectedTag = event.target.value;
        const response = await axios
            .post(`${apiurl}api/getHomePageLinks/`, {
                tag: selectedTag,
            })
            .catch((error) => {
                console.log(error);
            });
        dispatch(ListLinks(response?.data?.data));
    };

    const tagItems = listTags?.map((tag) => (
        <option key={tag.toString()}>{tag}</option>
    ));

    const colorItems = listColors?.map((color) => (
        <option key={color} value={color}>
            <span
                style={{
                    display: "inline-block",
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: color,
                    marginRight: "5px",
                }}
            />
            {color}
        </option>
    ));

    const handleColorChange = async (selectedOption) => {
        const response = await axios
            .post(`${apiurl}api/getHomePageLinks/`, {
                color: selectedOption.value,
            })
            .catch((error) => {
                console.log(error);
            });
        dispatch(ListLinks(response?.data?.data));
    };

    useEffect(() => {
        fetchTags();
        fetchColors();
    }, []);

    return (
        <section className="banner-section">
            <div className="row gx-5">
                <div className="col-md-5">
                    <h2 className="fw-bold h3 mb-2">
                        The Best Landing Page Design Inspiration Templates and
                        More.
                    </h2>
                    <p className="mb-5">
                        Looking for landing page inspiration? We've got you
                        covered. Landingfolio features the best landing page
                        designs.
                    </p>
                    <div className="row">
                        <div className="col-xxl-6 col-md-7">
                            <div className="icon-input-group mb-3">
                                <select
                                    onChange={handleChange}
                                    className="form-select"
                                >
                                    <option>Select Tag</option>
                                    {tagItems}
                                </select>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-md-5">
                            <ColorSelect
                                onColorChange={handleColorChange}
                                listColors={listColors}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-7 d-flex flex-column">
                    <div className="bg-white banner-image banner-clipart h-100 position-relative banner-no-repeat">
                        <img
                            src={BannerClip1}
                            alt=""
                            className="float top-left"
                        />
                        <img
                            src={BannerClip2}
                            alt=""
                            className="float top-right"
                        />
                        <img
                            src={BannerClip3}
                            alt=""
                            className="float bottom-left"
                        />
                        <img
                            src={BannerClip4}
                            alt=""
                            className="float bottom-right"
                        />

                        <div className="banner-box">
                            <div className="banners">
                                <div className="banner-text">
                                    Want your website
                                    <br /> in this list?
                                </div>
                                <button className="banner-button">
                                    Join waitlist
                                </button>
                            </div>
                            <img
                                className="banner-mask-group"
                                alt="Mask group"
                                src={BannerBackground}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BannerSection;
