import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import ImageDemo from "../Images/loader.svg";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import { Palette } from "react-palette";

const Detail = () => {
    const apiurl = "https://punchlinehub.com/";
    let showLoader = true;
    let demoImage = "";
    const linksList = useSelector((state) => state.allLinks.link);
    const [loader, setLoder] = useState(false);

    const websiteLink = linksList?.sitelink;
    const imageURL = linksList?.imageurl;
    const siteTag = linksList?.sitetag;
    let tagHTML = [];
    let SEOTags = [];

    if (linksList?.imageurl) {
        demoImage = `${apiurl}api/getHomePageImage/${linksList?.imageurl}`;
        showLoader = false;
    }
    if (siteTag) {
        linksList?.sitetag.map((tag, key) => {
            tagHTML.push(
                <Link key={key} to="/" className="badge-outline">
                    {tag}
                </Link>
            );
        });
    }
    const sendColorsToDatabase = async (colors, id) => {
        try {
            const response = await axios.post(
                "https://punchlinehub.com/api/updateSiteColor",
                {
                    id,
                    colors,
                }
            );
        } catch (error) {
            console.error("Error sending colors to database:", error);
        }
    };

    const [imageData, setImageData] = useState(null);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const response = await axios.get(demoImage, {
                    responseType: "blob",
                });
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImageData(reader.result);
                };
                reader.readAsDataURL(response.data);
            } catch (error) {
                console.error("Error fetching the image", error);
            }
        };

        fetchImage();
    }, [demoImage]);

    useEffect(() => {
        setLoder(true);
    }, []);

    return (
        <main className="main-wrapper">
            <Helmet>
                <title>Sizzle Snaps</title>
                <meta
                    name="description"
                    content="Sizzle snaps Best Landing page design inspiration website for Web3 and Portfolio sites"
                />
                <meta
                    name="keywords"
                    content={`${
                        SEOTags
                            ? SEOTags
                            : "Sizzle Snaps, New Design, Modern Design, Landing page, UI, UX, WEB3, NFT Market place, NFT, Dark UI"
                    }`}
                />
            </Helmet>

            <section className="banner-section">
                <div className="row">
                    <div className="col-xl-9">
                        <div className="d-flex flex-wrap align-items-start justify-content-between">
                            <div className="item">
                                <h2 className="h3 fw-bold d-flex align-items-center">
                                    Sizzle Snaps{" "}
                                    <span className="badge bg-dark ms-2 d-inline-block">
                                        Design
                                    </span>
                                </h2>
                                <p>
                                    Sizzle Snaps for landing page of Web3,
                                    Portfolio website, Dashboard, UI, UX, and
                                    many more
                                </p>
                            </div>
                            <div className="d-flex mb-4">
                                <button className="btn btn-dark">
                                    <span className="icon me-2">
                                        <svg
                                            width="21"
                                            height="18"
                                            viewBox="0 0 21 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M1.66214 1.61686C-0.554048 3.77268 -0.554047 7.26796 1.66214 9.42377L10.5217 18L19.3379 9.42399C21.554 7.26818 21.554 3.7729 19.3379 1.61709C17.1217 -0.53873 13.5285 -0.538731 11.3123 1.61709L10.5001 2.40717L9.68767 1.61686C7.47148 -0.538955 3.87833 -0.538954 1.66214 1.61686Z"
                                                fill="#D1D1D1"
                                            />
                                        </svg>
                                    </span>
                                    <span className="name">Save</span>
                                </button>
                                <Link
                                    to={websiteLink ? websiteLink : "/"}
                                    target="_blank"
                                    className="btn btn-dark flex-fill ms-3 px-6"
                                >
                                    Visit Site
                                </Link>
                            </div>
                        </div>

                        {showLoader ? (
                            <div className="loader-class">
                                <LazyLoadImage
                                    src={ImageDemo}
                                    width="100%"
                                    effect="blur"
                                    alt="Sizzlesnaps Image"
                                />
                            </div>
                        ) : (
                            ""
                        )}
                        <LazyLoadImage
                            src={demoImage}
                            width="100%"
                            effect="blur"
                            alt="Sizzlesnaps Image"
                        />
                    </div>
                    <div className="col-xl-3">
                        <div className="badge-wrapper">{tagHTML}</div>
                        <div className="device-theme-wrapper">
                            <div className="d-flex">
                                <select className="form-select glass-effect w-auto pe-5">
                                    <option>Device: Desktop</option>
                                    <option>Device: Mobile</option>
                                    <option>Device: Tablet</option>
                                </select>

                                <span className="form-control glass-effect ms-2 dt-wrapper">
                                    {Array.isArray(linksList?.sitecolors) &&
                                    linksList?.sitecolors?.length > 0 ? (
                                        <div>
                                            {linksList?.sitecolors?.map(
                                                (color, index) => (
                                                    <Link
                                                        key={index}
                                                        to="#"
                                                        className={`round-circle`} // Assuming colorList items have a className property
                                                        style={{
                                                            backgroundColor:
                                                                color,
                                                        }} // Assuming colorList items have the color in a hex property
                                                    ></Link>
                                                )
                                            )}
                                        </div>
                                    ) : (
                                        <Palette src={imageData}>
                                            {({ data, loading }) => {
                                                if (loading)
                                                    return (
                                                        <div>
                                                            Loading color
                                                            palette...
                                                        </div>
                                                    );

                                                if (data && data.vibrant) {
                                                    if (
                                                        linksList &&
                                                        linksList._id &&
                                                        (!linksList.sitecolors ||
                                                            linksList.sitecolors
                                                                .length < 1)
                                                    ) {
                                                        sendColorsToDatabase(
                                                            data,
                                                            linksList._id
                                                        );
                                                    }
                                                }

                                                return (
                                                    <div>
                                                        <Link
                                                            to="#"
                                                            className="round-circle b-info"
                                                            style={{
                                                                backgroundColor:
                                                                    data.vibrant,
                                                            }}
                                                        ></Link>
                                                        <Link
                                                            to="#"
                                                            className="round-circle b-success"
                                                            style={{
                                                                backgroundColor:
                                                                    data.darkVibrant,
                                                            }}
                                                        ></Link>
                                                        <Link
                                                            to="#"
                                                            className="round-circle b-warning"
                                                            style={{
                                                                backgroundColor:
                                                                    data.lightVibrant,
                                                            }}
                                                        ></Link>
                                                        <Link
                                                            to="#"
                                                            className="round-circle b-dark"
                                                            style={{
                                                                backgroundColor:
                                                                    data.muted,
                                                            }}
                                                        ></Link>
                                                        <Link
                                                            to="#"
                                                            className="round-circle b-pink"
                                                            style={{
                                                                backgroundColor:
                                                                    data.darkMuted,
                                                            }}
                                                        ></Link>
                                                    </div>
                                                );
                                            }}
                                        </Palette>
                                    )}
                                    <Link to="#" className="download-icon">
                                        <svg
                                            width="14"
                                            height="17"
                                            viewBox="0 0 14 17"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M6.14541 10.6681C6.14541 10.5121 6.14541 10.4108 6.14541 10.3091C6.14541 7.20545 6.14541 4.10177 6.14541 0.998087C6.14541 0.509911 6.38855 0.150182 6.79182 0.0333766C7.32988 -0.122622 7.86501 0.282509 7.8977 0.870804C7.90137 0.935222 7.89954 1.00003 7.89954 1.06483C7.89954 4.17511 7.89954 7.285 7.89954 10.3953C7.89954 10.4698 7.89954 10.5443 7.89954 10.6658C7.9807 10.587 8.02919 10.5431 8.07473 10.4954C9.05316 9.46317 10.0309 8.42977 11.0093 7.39754C11.3938 6.99202 11.9264 6.97495 12.2856 7.35447C12.6459 7.73515 12.6198 8.31336 12.2353 8.71926C10.7158 10.3223 9.19861 11.9273 7.68247 13.5335C7.29867 13.9402 6.75876 13.9487 6.37569 13.544C4.85772 11.9397 3.34049 10.334 1.82032 8.73207C1.54449 8.44141 1.42769 8.10885 1.5533 7.71148C1.66863 7.34632 1.92426 7.13948 2.28235 7.09097C2.58169 7.05023 2.82997 7.17208 3.04189 7.39638C4.02216 8.43521 5.00537 9.47171 5.98748 10.5086C6.02567 10.549 6.06571 10.5881 6.14504 10.6681H6.14541Z"
                                                fill="#131313"
                                            />
                                            <path
                                                d="M7.01909 16.78C5.26313 16.78 3.50716 16.7819 1.75119 16.7788C1.17787 16.7777 0.785247 16.3194 0.84695 15.7392C0.889921 15.3368 1.18889 15.005 1.56792 14.9449C1.65827 14.9305 1.75083 14.927 1.84228 14.927C5.29912 14.9262 8.75596 14.9259 12.2128 14.9266C12.8453 14.9266 13.2463 15.3403 13.2019 15.9418C13.1696 16.3811 12.8317 16.7404 12.4152 16.7749C12.33 16.7819 12.244 16.7804 12.1584 16.7804C10.4454 16.7804 8.73209 16.7804 7.01909 16.7804V16.78Z"
                                                fill="#131313"
                                            />
                                        </svg>
                                    </Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default Detail;
