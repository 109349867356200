import "./Style/style.css";
import HomePage from "./components/HomePage";
import TagsPage from "./components/TagsPage";
import CommingSoon from "./components/CommingSoon";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import DetailComponent from "./components/DetailComponent";

function App() {
    return (
        <HelmetProvider>
            <Helmet>
                <title>
                    Sizzle snaps Best Landing page design inspiration website
                    for Web3 and Portfolio sites
                </title>
                <meta
                    name="description"
                    content="Get best idea for your next project of We3, NFTs, NFT market place, Metaverse, Portfolio sites, Figma design, UI Ideas, UX Ideas, Dark UI, Landing pages, and many more."
                />

                {/* Twitter meta tags below */}
                <meta name="twitter:card" content="summary" />
                <meta
                    name="twitter:image"
                    content="https://designcode.io/cloud/v2/twitter.jpg"
                />
                <meta
                    name="twitter:title"
                    content="Sizzle snaps Best Landing page design inspiration website for Web3 and Portfolio sites"
                />
                <meta name="twitter:creator" content="@ndsnaren" />
                <meta name="twitter:site" content="@ndsnaren" />
                <meta
                    name="twitter:description"
                    content="Get best ideas of landing page, WEB3 websites, NFT marketplace, and Dark UI"
                />

                {/* Facebook meta tags below */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content="www.designidea.io" />
                <meta
                    property="og:title"
                    content="Sizzle snaps Best Landing page design inspiration website for Web3 and Portfolio sites"
                />
                <meta
                    property="og:description"
                    content="Learn design and code by building real apps with React and Swift. Complete courses about UI design, web and iOS development using Figma, CSS, React Hooks and SwiftUI."
                />
                <meta property="og:image" content="https://designidea.io" />
            </Helmet>

            <Router>
                <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route exact path="/details" component={DetailComponent} />
                    <Route exact path="/tagspage" component={TagsPage} />
                    <Route exact path="/designidea" component={CommingSoon} />
                </Switch>
            </Router>

            {/* <HomePage /> */}
        </HelmetProvider>
    );
}

export default App;
