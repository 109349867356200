import React from 'react';
import Header from './Header';
import SideBar from './SideBar';
import Body from './Body';

const HomePage = () => {
    return (
        <>
            <Header />
            <div className="page-wrapper flex-column-reverse flex-lg-row sticky-sidebar-custom">
                <SideBar />
                <Body />
            </div>
        </>
    )
}

export default HomePage;