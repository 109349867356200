import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { ListLinks } from "../redux/actions/LinksActions";
import LinkImage from "../Images/design-idea-link.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const MiddleSection = () => {
    const apiurl = "https://punchlinehub.com/";
    const linksList = useSelector((state) => state.allLinks.links);
    const dispatch = useDispatch();
    const loading = linksList?.length === 0;

    const fetchLinks = async () => {
        const response = await axios
            .get(`${apiurl}api/getHomePageLinks`)
            .catch((error) => {
                console.log(error);
            });
        dispatch(ListLinks(response?.data?.data));
    };

    useEffect(() => {
        fetchLinks();
    }, []);

    return (
        <section className="theme-section">
            <div className="row gy-4 gx-5">
                {loading
                    ? Array.from({ length: 8 }).map((_, index) => (
                          <div
                              key={index}
                              className="col-sm-6 col-320 col-lg-3"
                          >
                              <div className="t-thumb-item">
                                  <Skeleton height={500} />
                                  <div className="t-thumb-footer">
                                      <Skeleton width={`80%`} />
                                  </div>
                              </div>
                          </div>
                      ))
                    : linksList?.map((data, key) => {
                          const { sitelink, thumbnail, _id, imageurl } = data;
                          let imageUrl = `${apiurl}api/getHomePageImage/${thumbnail}`;
                          return (
                              <div
                                  key={key}
                                  className="col-sm-6 col-320 col-lg-3"
                              >
                                  <Link to={`/details?pageId=${_id}`}>
                                      <div className="t-thumb-item">
                                          <img
                                              src={imageUrl}
                                              className="thumbnail-image"
                                              width="100%"
                                              effect="blur"
                                              alt="Sizzlesnaps Image"
                                          />
                                          <Link
                                              to={`/details?pageId=${_id}`}
                                              className="t-thumb-footer"
                                          >
                                              <span className="text">
                                                  {new URL(sitelink).hostname}
                                              </span>
                                              <span className="icon">
                                                  {/* SVG content */}
                                              </span>
                                          </Link>
                                          <div className="middle">
                                              <img
                                                  src={LinkImage}
                                                  className={"middle-image"}
                                                  alt="Link representation"
                                              />
                                          </div>
                                      </div>
                                  </Link>
                              </div>
                          );
                      })}
            </div>
        </section>
    );
};

export default MiddleSection;
