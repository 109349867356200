import React from "react";

const SideBar = () => {
  return (
    <>
    </>
    // <aside className="sidebar-wrapper sticky-sidebar-custom">
    //   <div className="sidebar-menu">
    //     <h4 className="title">Pages</h4>
    //     <a href={`/tagspage?tagName=Design`} className="page-link active">
    //       Landing Page
    //     </a>
    //     <a href="/" className="page-link">
    //       Pricing Page
    //     </a>
    //     <a href="/" className="page-link">
    //       About Page
    //     </a>
    //     <a href="/" className="page-link">
    //       Login Page
    //     </a>
    //     <a href="/" className="page-link">
    //       Signup Page
    //     </a>
    //     <a href="/" className="page-link">
    //       Not Found Page
    //     </a>
    //     <h4 className="title">Industries</h4>
    //     <a href="/" className="page-link">
    //       Marketing
    //     </a>
    //     <a href="/" className="page-link">
    //       Design Tools
    //     </a>
    //     <a href="/" className="page-link">
    //       Business
    //     </a>
    //     <a href="/" className="page-link">
    //       Software
    //     </a>
    //     <a href="/" className="page-link">
    //       Mobile App
    //     </a>
    //     <a href="/" className="page-link">
    //       Technology
    //     </a>
    //     <a href="/" className="page-link">
    //       Freelance
    //     </a>
    //     <a href="/" className="page-link">
    //       Artifical Integence
    //     </a>
    //     <a href="/" className="page-link">
    //       404 error
    //     </a>
    //     <a href="/" className="page-link">
    //       Users
    //     </a>
    //     <a href="/" className="page-link">
    //       Crypto
    //     </a>
    //   </div>
    // </aside>
  );
};

export default SideBar;
