import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import queryString from "query-string";
import { ListLinks } from "../redux/actions/LinksActions";
import BannerSection from "./BannerSection";
import Header from "./Header";
import SideBar from "./SideBar";

const TagsPage = () => {
    const linksList = useSelector((state) => state.allLinks.links);
    const dispatch = useDispatch();

    const value = queryString.parse(window.location.search);
    const token = value.tagName;
    const pageURL = token;

    const fetchLinks = async () => {
        const response = await axios
            .post("https://eight-dollars.com/api/getLinkBasedOnTags", {
                tagName: pageURL,
            })
            .catch((error) => {
                console.log(error);
            });
        dispatch(ListLinks(response.data.data));
    };

    useEffect(() => {
        fetchLinks();
    }, []);

    let dataList = [];
    linksList.map((data, key) => {
        const { sitelink, thumbnail, _id, imageurl } = data;
        let imageUrl = `https://eight-dollars.com/api/getHomePageImage/${thumbnail}`;

        dataList.push(
            <div key={key} className="col-sm-6 col-320 col-lg-4">
                <div className="t-thumb-item">
                    <img src={imageUrl} alt="" width="100%" />
                    <a
                        href={`/details?pageId=${_id}`}
                        className="t-thumb-footer"
                    >
                        <span className="text">
                            {new URL(sitelink).hostname}
                        </span>
                        <span className="icon">
                            <svg
                                width="8"
                                height="16"
                                viewBox="0 0 8 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.00015 2L6.99984 8.03366"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                />
                                <path
                                    d="M6.99974 7.96631L1.00005 14"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </span>
                    </a>
                    <a href="/" className="heart-top-right">
                        <svg
                            width="21"
                            height="18"
                            viewBox="0 0 21 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.66214 1.61686C-0.554048 3.77268 -0.554047 7.26796 1.66214 9.42377L10.5217 18L19.3379 9.42399C21.554 7.26818 21.554 3.7729 19.3379 1.61709C17.1217 -0.53873 13.5285 -0.538731 11.3123 1.61709L10.5001 2.40717L9.68767 1.61686C7.47148 -0.538955 3.87833 -0.538954 1.66214 1.61686Z"
                                fill="#D1D1D1"
                            />
                        </svg>
                    </a>
                </div>
            </div>
        );
    });

    return (
        <>
            <Header />
            <div className="page-wrapper flex-column-reverse flex-lg-row sticky-sidebar-custom">
                <SideBar />
                <main className="main-wrapper">
                    <BannerSection />
                    <section className="theme-section">
                        <div className="row gy-4 gx-5">{dataList}</div>
                    </section>
                </main>
            </div>
        </>
    );
};

export default TagsPage;
