import { ActionTypes } from "../contants/action-types";

const initialState = {
    links: [],
};

export const linkReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.LINK_LISTS:
            return { ...state, links: payload };
        case ActionTypes.SINGLE_LINK:
            return { ...state, link: payload };
        default:
            return state;
    }
};
