import React from "react";
import logoImage from "../Images/sizzlesnaps.svg";
import { BrowserRouter as Router, Link } from "react-router-dom";
const Header = () => {
    return (
        <header>
            <nav className="navbar navbar-expand-lg fixed-top bg-white">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                        <img src={logoImage} alt="logo" />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className="collapse navbar-collapse top-button-blur"
                        id="navbarSupportedContent"
                    >
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link to={`/designidea`} className="nav-link">
                                    Design Style{" "}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/designidea`} className="nav-link">
                                    Inspiration{" "}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/designidea`} className="nav-link">
                                    Learn Design{" "}
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={`/designidea`} className="nav-link">
                                    600+ Freebies
                                </Link>
                            </li>
                        </ul>
                        <div className="button-row ms-auto top-button-blur">
                            <button className="btn rounded-pill btn-outline-dark">
                                Support
                            </button>
                            <button className="btn rounded-pill btn-dark ms-2">
                                Sign Up
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
