import React from 'react';
import BannerSection from './BannerSection';
import MiddleSection from './MiddleSection';

const Body = () => {
    return (
        <main className="main-wrapper">
            <BannerSection />
            <MiddleSection />
        </main>
    )
}

export default Body;